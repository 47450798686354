<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Briefing</strong>
          </CCardHeader>
         <CRow>
         <CCol md="8"> 

          <CCardBody>
            <CForm>
           

                
              <CInput
                label="First Name *"
                v-model="user.firstname "
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Topic'"
                   
                    @blur="$v.user.firstname.$touch()"
             />
           <CInput
                label="Last Name *"
                v-model="user.lastname "
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Topic'"
                   
                    @blur="$v.user.lastname.$touch()"
             />
              <CInput
                label="Email *"
                v-model="user.email"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Email'"
                   
                    @blur="$v.user.email.$touch()"
              />

            
              
             
              <div class="form-group row">
						 <label for="example-text-input text-right" class="col-sm-3 col-form-label"> Role *</label>
						 <div class="col-sm-9">
						
							<select class="form-control"  v-model="user.role" :disabled="mode.viewMode" >
										  <option disabled value="">--Select One Option--</option>
										  <option value="1"></option>
										  <option value="2"></option>
								 </select>
								 </div>
						   </div>


  <CInput
                label="Password *"
                v-model="user.password"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Time'"
                  
                    @blur="$v.user.password.$touch()"
              />
             
            </CForm>
            
          </CCardBody>
          </CCol>
          
          </CRow>
          
          <CCardFooter align="right">
         <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
 import Vue from "vue";
 import datetime from 'vuejs-datetimepicker';
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "MyUser",
  data() {
    return {
      mode:{
        newMode:false,
        editMode:false,
        viewMode:false,
      },
     uid:"",
      user:{
           uid:"0",
        firstname:'',
        lastname:'',
        role:'',
        email:'',
        password:'',
      
      },
   
      horizontal: { label: "col-3", input: "col-9" },
    
      
    //   selectedOption: "some value",

    //   formCollapsed: true,
    //   checkboxNames: [
    //     "Checkboxes",
    //     "Inline Checkboxes",
    //     "Checkboxes - custom",
    //     "Inline Checkboxes - custom",
    //   ],
     
    };
 },

 validations: {
   user: {
      
      firstname: {
           required,
       
      },
   lastname: {
           required,
       
      },
      role: {
           required,
       
      },
       email:{
           required,
       
      },
      password:{
           required,
       
      },
    },
  },
   created() {  
             if (this.$route.params.mode == "new") {
                      this.mode.newMode = true;
                        this.mode.viewMode = false;
                        this.mode.editMode = false;
                 }
             if(this.$route.params.mode =='view'){
                   this.mode.viewMode=true;
                    this.briefingId = this.$route.params.briefingId;
                    this.getUser();
            }
            if (this.$route.params.mode == "edit") {
                this.mode.editMode = true;
                this.briefingId = this.$route.params.briefingId;
                 this.getUser();
    }
        },
         components: { datetime },
  methods: {
    editMode(){
       this.mode.editMode=true;
        this.mode.viewMode=false;
    },
     cancel() {
      this.$router.push({ name: 'MyUsers' })
    },
    saveSpeciality() {
      this.$v.briefing.$touch();
      if (this.$v.briefing.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.briefing));
      }else{
     this.apiPostSecure(process.env.VUE_APP_API_HOST + "/web/faq/save", this.briefing
      ).then((post) => {
          this.$router.push({ name: "MyUsers" });
        });
      }
     
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    
    
    getUser() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/web/secure/get/faq/"+this.briefingId)
        .then((response) => response.json())
        .then( (data)  => (this.briefing = data) );
        
    },
    // getRoles() {
    // fetch(process.env.VUE_APP_API_HOST +"/web/secure/meta/roles")
    //     .then((response) => response.json())
    //     .then( (data)  => (this.roles = data) );
    // },
   
  },
  mounted() {
    //this. getRoles();
   
  }
};
</script>
